@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Donegal+One&display=swap');

    * {
        font-family: 'Inter', sans-serif;
        scroll-behavior: smooth;
    }

    html {
        min-width: 320px;
        overflow-x: hidden;
    }

    html section {
        scroll-snap-align: start;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply text-white;
        @apply uppercase;
        text-wrap: balance;
    }

    p, div {
        @apply text-white;
        text-wrap: balance;
    }

    a:not(.footer-link) {
        text-decoration: underline;
    }

    .slide-left {
        animation: slideLeft 1s ease-in-out;
    }

    .scale-down {
        animation: scaleDown 1s ease-in-out;
    }

    @keyframes slideLeft {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%)!important;
        }
    }

    @keyframes scaleDown {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(.6);
        }
    }
    
    /* Slider */
        /* All (prev, active, next and all others) */
        [data-image-nr] {
            transform: translate(-50%, -50%) translateX(-25%) scale(.65);
            z-index: 1;
            transition-property: position, transform, opacity; transition-duration: .5s; transition-timing-function: ease-in-out;
            opacity: 0;
        }

        /* All in view (prev, active, next) */
        [data-image-nr="prev"],
        [data-image-nr="active"],
        [data-image-nr="next"] {
            opacity: 1;
        }

        /* Prev & next */
        [data-image-nr="prev"][data-direction-to="prev"],
        [data-image-nr="next"][data-direction-to="next"] {
            z-index: 1;
        }
        [data-image-nr="prev"][data-direction-to="next"],
        [data-image-nr="next"][data-direction-to="prev"] {
            z-index: 2;
        }

        /* Active */
        [data-image-nr="active"] {
            transform: translate(-50%, -50%);
            z-index: 3;
        }

        /* Next */
        [data-image-nr="next"] {
            transform: translate(-50%, -50%) translateX(25%) scale(.65);
            z-index: 2;
        }
    /* End slider */

    .progress-animation {
        animation: progress 3s ease-in-out;
        width: 0;
    }

    @keyframes progress {
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
    }

    .glass {
        background-color: rgba(0, 0, 0, 0.25)!important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
    
    .animating-border {
        animation: animatingBorder 15s infinite alternate-reverse;
    }

    .map-container {
        width: 100%;
        aspect-ratio: 16/6;
    }
    
    .map-wrapper {
        height: 100%;
        width: 100%;
    }

    @keyframes animatingBorder {
        0% {
            background-position: left;
        }
        100% {
            background-position: right;
        }
    }

    :root{
        --primary-grey: #4F4F4F;
        --primary-yellow: '#DDAE3A';
        --primary-green: '#26684B';
    
        --transition-duration: 0.2s;
    }

    .overflow-inherit {
        overflow: inherit!important;
    }

    .image-list .animated-div{
        width: 100%;
        height: 100%;
    }
    .image-list .animated-div img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }

    .link-element{
        padding: 5px 15px!important;
    }


    /* tailwinds media queries */
    @media (max-width: 768px) {
        #root section div h2:not(.overide-static-font), #root div h2:not(.overide-static-font) {
            @apply text-5xl;
        }
        #root section div h3:not(.overide-static-font), #root div h3:not(.overide-static-font) {
            @apply text-4xl;
        }
        #root section div h4:not(.overide-static-font), #root div h4:not(.overide-static-font) {
            @apply text-2xl;
        }
        #root section div h5:not(.overide-static-font), #root div h5:not(.overide-static-font) {
            @apply text-xl;
        }
        #root section div h6:not(.overide-static-font), #root div h6:not(.overide-static-font) {
            @apply text-lg;
        }
    }

    @media (max-width: 567px) {
        #root section div h1:not(.overide-static-font) {
            @apply text-4xl;
        }
        #root section div h2:not(.overide-static-font) {
            @apply text-3xl;
        }
        #root section div h3:not(.overide-static-font) {
            @apply text-2xl;
        }
        #root section div h4:not(.overide-static-font) {
            @apply text-xl;
        }
        #root section div h5:not(.overide-static-font) {
            @apply text-lg;
        }
        #root section div h6:not(.overide-static-font) {
            @apply text-base;
        }
    }    


    /* slideDown animation with keyframes */
    @keyframes slideDown {
        0% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
        100% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
    }
    .slideDown {
        animation: slideDown var(--transition-duration) ease-in-out;
    }

    /* slideUp animation with keyframes */
    @keyframes slideUp {
        0% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
        100% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
    }
    .slideUp {
        animation: slideUp var(--transition-duration) ease-in-out;
    }



    /* Custom Browser compatabilities */
    .safari .team-card {
        width: 90%!important;
        height: 90%!important;
        margin: 0 auto!important;
    }
    .team-card .team-card-img-wrapper {
        width: 100%!important;
        height: 100%!important;
    }



    .CookieConsent {
        background: var(--primary-grey);
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        z-index: 15000;
        text-align: center;
        width: 300px;
        -webkit-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.46);
        -moz-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.46);
        box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.46);
    }

    @media (max-width: 768px) {
        .CookieConsent {
            left: 0;
            right: 0;
            margin: 1rem auto;
        }
    }

    .CookieConsent > div:nth-child(2)  {
        background: rgb(38,104,75);
        background-size: 220%!important;
        background-image: linear-gradient(146deg, rgba(38,104,75,1) 0%, rgba(221,174,58,1) 50%, rgba(38,104,75,1) 70%, rgba(221,174,58,1) 100%);
        background-position: left;
        transition: background-position 500ms!important;
        border-radius: 18px;
        padding: 2px;
        width: fit-content;
        margin: 10px auto;
    }

    .CookieConsent > div:nth-child(2):hover {
        background-position: right;
    }
    
    .CookieConsent > div:nth-child(2) > button {
        background: var(--primary-grey);
        width: 100%;
        border-radius: 20px;
        padding: 4px 20px;
    }
}
